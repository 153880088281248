/* Global Styles */
body {
  font-family: 'Raleway', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f1f1f0;
  color: #333;
}

a {
  text-decoration: none;
  color: inherit;
}

h1, h2, h3 {
  color: #51624F;
}

/* App Wrapper */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Content Area */
.content {
  flex: 1;
  margin-right: 0px;
  margin: 0 auto;
}





